<template>
  <div class="title">404 page</div>
  <div class="btn">
    <router-link to="/mainhome/simplifyhome" class="to_page">
      返回首页
    </router-link>
    <a href="javascript:;" @click="back" class="to_page"> 返回上一页 </a>
  </div>
</template>

<script>
export default {
  name: '404',
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.title {
  margin-top: 100px;
  margin-bottom: 10px;
  color: #8877ff;
  font-size: 40px;
  text-align: center;
  font-weight: 700;
}
.btn {
  text-align: center;
}
.to_page {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: LEFT;
  color: #8c8c8c;
}
</style>
